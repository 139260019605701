/* Bootstrap GRID */


body.grid {
}

#divGrid {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 1000%;
    width: 100%;
    opacity:0.4;
    z-index:9999;
    pointer-events: none;
}

#divGrid .container {
    height: 100%;
    background-image: linear-gradient(rgba(0,0,0,.8) 1px, transparent 1px);
    background-size: 100% $baseline;
    background-position: 0 0;
    background-origin: content-box;
}

body.grid #divGrid {
    display: block;
}

body.grid h1, body.grid h2, body.grid h1, body.grid h3, body.grid h4, body.grid .more, body.grid img, body.grid .arrow-top {
    background:#ccc;
}

body.grid .module, body.grid #footer {
    background:#ffffcc;
}

body.grid p, body.grid h5 {
    background:#bbffbb;
}

body.grid .bignumber, body.grid ul {
    background: #99ffff;
}


#divGrid .col-md-1:nth-child(odd),
#divGrid .col-sm-1:nth-child(odd),
#divGrid .col-xs-1:nth-child(odd),
#divGrid .col-lg-1:nth-child(odd) {
    background-color: rgba(255,0,0,0.5);
    height: 100%;
    border-left: solid $baseline/2 rgba(0, 255, 0, 0.5);
    border-right: solid $baseline/2 rgba(0, 255, 0, 0.5);
}

#divGrid .col-md-1:nth-child(even),
#divGrid .col-sm-1:nth-child(even),
#divGrid .col-xs-1:nth-child(even),
#divGrid .col-lg-1:nth-child(even) {
    background-color: rgba(255,0,0,0.5);
    height: 100%;
    border-left: solid $baseline/2 rgba(0, 255, 0, 0.5);
    border-right: solid $baseline/2 rgba(0, 255, 0, 0.5);
}

#divGrid .container {
    height: 100%;
}


#divGrid .row {
    height: 100%;
}

#divGrid .row .col-xs-1,#divGrid .row .col-sm-1,#divGrid .row .col-md-1,#divGrid .row .col-lg-1 {
    padding: 0px!important;
}


@media (max-width: 767px) {

    body {
        padding-left: 0px;
        padding-right: 0px;
    }

    #divGrid .row [class*="col"] {
        float: left;
    }

    #divGrid .row .col-md-1 {
        /*width: 4.3478260869565215%;
        margin-left: 4.3478260869565215%;*/
    }

    #divGrid .row [class*="col-md-1"]:first-child {
        margin-left: 0%;
    }


}
