.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: $spacing--small;
    &__backdrop {
        @extend .backdrop;
		animation: fadeIn .1s $trans forwards;
    }
    &__content {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $spacing;
        z-index: 40;
        top: 0;
        width: 400px;
        max-width: 100%;
        overflow: hidden;
        background-color: $c-violet;
        color: white;
		border: $border-width--large solid $c-violet;
        padding: $spacing;
        @include bp(md) {
            padding: $spacing--large;
        }
        &:focus {
            outline: 0;
        }
        transform-origin: left top;
        &--animate {
            transition: transform 300ms ease-out,
                        opacity   300ms cubic-bezier(0,1,0,1);
        }
    }
    &__title {
        margin-bottom: $spacing;
        text-align: center;
    }
    &__close {
        position: absolute;
        top: $spacing;
        right: $spacing;
        color: white;
    }
    &--tight {
        .modal__content {
            width: 460px;
        }
    }
    &--complete {
        .modal__content {
            background: green;
        }
    }
    &__complete {
        // padding-top: $spacing;
        opacity: 0;
        animation: fadeInUp .3s $trans forwards;
    }
    &__icon-complete {
        animation: fadeInUp $trans;
        width: $spacing--large;
        margin: 0 auto;
        svg {
            fill: white;
        }
    }
}
