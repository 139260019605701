@keyframes fadeOutIn {
	0% {
		opacity: 1;
		margin-top: 0px;
	}
	20% {
		opacity: 0;
		margin-top: 0px;
	}
	80% {
		opacity: 0;
		margin-top: 100px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes skew {
	0% {
		transform: skew(0);
	}
	50% {
		transform: skew(30deg);
	}
	100% {
		transform: skew(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(#{$spacing--large});
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
