.list-images {
	display: block;
	@include bp($sm) {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		display: inline-block;
		width: 100%;
		margin-right: $spacing;
		@extend .meta;

		@include bp($sm) {
			width: 25%;
		}
	}
	&__media {
		padding-bottom: $spacing--small;
	}
	&__list {
		@extend .list--reset;
	}
	&__name {
		font-family: $font-family;
	}
}
