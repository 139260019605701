select {
	appearance: none;
	background: $c-violet;
	padding: $spacing--small;
	margin-bottom: $spacing--small;
	display: block;
	color: white;

	&:after {
		content: "\2193;";
		color: red;
	}
}

label {
	@extend .meta;
	display: block;
	font-family: $font-family;
	text-align: left;
	margin-bottom: $spacing--xsmall;
}

input {
	width: 100%;
	padding: $spacing--xsmall;
	border: $border-width solid $c-violet;
}

textarea {
	width: 100%;
	height: $baseline*4;
	border: none;
	padding: $spacing--xsmall;
}
