.author {
	@extend .cf;
	margin-bottom: $spacing;

	&__fig {
		border-radius: 50%;
		width: $spacing--large;
		height: $spacing--large;
		overflow: hidden;
		float: left;
		margin-right: $spacing--small;
	}
	&__img {
	}
	&__content {
		float: left;
	}
	&__name {
		margin-bottom: 0;
	}
	&__title {}

	@include bp($sm) {
		width: 300px;
	}
	&--small {
		margin-top: -$spacing--large + $spacing;
		text-align: right;
	}
}
