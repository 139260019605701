.button {
	background: $c-violet;
	border: $border-width solid $c-violet;
	padding: $spacing--small;
	margin-right: $spacing;
	text-align: center;
	color: white;
	transition: $trans--fast;

	h2 {
		margin: 0;
		padding: 0;
	}

	&:hover {
		background: none;
		color: $c-violet;
	}

	&:active {
		transform: scale(1.01);
	}

	&--small {
		// padding: $spacing--xsmall;
	}

	&--full {
		width: 100%;
	}
	&--large {
		padding: $spacing;
	}
	&--shadow {
		box-shadow: 0 0 10px rgba(black, .5);
	}
	&--white {
		color: white;
		border-color: white;

		&:hover {
			background: white;
			color: $c-violet;
		}
	}
	&--small {
		padding: 9px;
	}

	&--secondary {
		background: none;
		color: $c-violet;

		&:hover {
			background: $c-violet;
			color: white;
		}
	}
	&--success {
		background: green;
		&:hover {
			background: green;
		}
	}
}

.no-button {
	background: transparent;
	border: none;
	color: $c-violet;
	padding: 0;
}
