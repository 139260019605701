$baseline: 26px; // Mother of all variables

$gutter: $baseline;
$xs: 400px;
$sm: 768px - $gutter; // - $gutter to compensate for container padding
$md: 992px - $gutter;
$lg: 1200px - $gutter;

// Sidespace

$sidespace: 0;

$sidespace--sm: calc((100vw - #{$sm}) / 2);
$sidespace--md: calc((100vw - #{$md}) / 2);
$sidespace--lg: calc((100vw - #{$lg}) / 2);

$sidespace-neg--sm: calc((-100vw + #{$sm}) / 2);
$sidespace-neg--md: calc((-100vw + #{$md}) / 2);
$sidespace-neg--lg: calc((-100vw + #{$lg}) / 2);

// Colors
$c-blue: #55C2CE;
$c-grey: #58595B;
$c-grey--light: #e6e6e6;
$c-yellow: #FFF59D;
$c-red: #FFA348;
$c-violet: #4D3E98;
$c-gold: #AF9C67;
$c-white--overlay: rgba(white,.5);
// $c-blue: #42bbc9; // Original blue

$c-project: $c-gold;
$c-event: $c-blue;
$c-news: $c-red;
$c-blog: $c-yellow;

$c-project--gradient: linear-gradient($c-gold, $c-gold);
$c-event--gradient: linear-gradient($c-blue, $c-blue);
$c-news--gradient: linear-gradient($c-red, $c-red);
$c-blog--gradient: linear-gradient($c-yellow, $c-yellow);

// Fonts & sizes
$font-family: 'Replica-regular', 'Helvetica Neue', Helvetica, sans-serif;
$font-family--bold: 'Replica-bold', 'Helvetica Neue', Helvetica, sans-serif;
$font-family--light: 'Replica-light', 'Helvetica Neue', Helvetica, sans-serif;
$font-family--italic: 'Replica-italic', 'Helvetica Neue', Helvetica, sans-serif;
$font-family--light--italic: 'Replica-Lightitalic', 'Helvetica Neue', Helvetica, sans-serif;
$font-family--mono: 'Replica-mono', monospaced;

// $fz is based on emmets shortening of font-size
// lh is based on emmets shortening of line-height
$h1__fz: 50px;
$h1__lh: 60px;

$h2__fz: 26px;
$h2__lh: 30px;

$h3__fz: 18px;
$h3__lh: $baseline;

$h4__fz: 50px;
$h4__lh: 50px;

$h5__fz: 50px;
$h5__lh: 50px;

$p__fz: 18px;
$p__lh: $baseline;

$meta__fz: 15px;
$meta__lh: 18px;


// Spacingunits

$spacing--xsmall: $baseline/4;
$spacing--small: $baseline/2;
$spacing: $baseline;
$spacing--large: $baseline*2;
$spacing--xlarge: $baseline*3;
$spacing--section: $baseline*4;
$spacing--sub-section: $spacing--section / 2;

// Lines

$border-width--small: 1px;
$border-width: 1px;
$border-width--large: 3px;

$border-color--light: lighten(gray, 40%);
$border-color: gray;
$border-color--dark: darken(gray, 40%);

// Transitions
$bezier: cubic-bezier(.45,.17,.48,.79);
$trans--fast: .1s $bezier;
$trans: .3s $bezier;
$trans--slow: .8s $bezier;

// Transforms
$skew: skewX(-15deg);
