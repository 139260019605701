.factbox {
	background: rgba(white,.2);
	padding: $spacing--small;
	margin-bottom: $spacing--large;
	margin-top: $spacing--large;
	width: 100%;
	&__title {
		border-bottom: 2px solid $c-gold;
		margin: 0;
		// Even spacing from the line
		padding-bottom: $spacing--xsmall / 2;
		margin-bottom: $spacing--xsmall / 2;
		padding-top: 0;
	}

	@include bp($sm) {
		padding: $spacing;

		&__row {
			display: flex;
		}
		&__item {
			width: 50%;
			margin-right: $spacing;
			margin-bottom: $spacing--small;
		}
	}
}
