body {
	background: lighten($c-gold, 60%);
}

table {
	width: 100%;
	margin-bottom: $spacing--large;
}

td {
	text-align: left;
	padding: $spacing--small $spacing--small $spacing--small 0;
	border-bottom: $border-width--small solid $c-white--overlay;
}

th {
	font-family: $font-family;
}

// TODO Make this a module
dl {
	@extend .cf;
	overflow: hidden; // TODO @extend not working for some reason
	margin-bottom: $spacing--sub-section;
}

dt, dd {
	width: 50%;
	padding: $spacing--small 0;
	border-bottom: $border-width--small solid $c-white--overlay;
}

dt {
	float: left;
	font-family: $font-family;
	font-size: 15px;
}
dd {
	float: right;
	text-align: right;
}


h1 + .section + h2{
	margin-top: 0 !important;
}

img {
	width: 100%;
}
h2, h3 {
	// transform: $skew;
}

a {
	// display: inline-block;
	text-decoration: none;
	color: inherit;
	transition: $trans--fast;
	p {
		transition: $trans--fast;
	}
}
ul ul {
	padding-left: $spacing;
}
li {
	padding: 0;
}
a {
	color: $c-violet;
	border-bottom: 1px solid;
	transition: $trans--fast;
	&:hover {
		// transform: $skew;
		border-color: transparent;
	}
	&.active {
		border-bottom: 1px solid $c-violet;
	}
}
a.no-link {
	color: black;
	&:hover {
		transform: none;
	}
}

.main-gallery {
	max-height: 60vh;
	margin-bottom: $spacing--large;
	box-shadow: -7px 0 50px rgba(black, .4);
	img {
		width: auto;
		max-height: 60vh;
	}
	.gallery-cell {
		max-height: 60vh;
	}
}

.main {
	margin-top: 53px;
}

// MODAL
.backdrop {
    &.ng-enter {
        animation: fadeIn .1s $trans forwards;
    }
    &.ng-leave {
        animation: fadeOut .1s $trans forwards;
    }
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(black, 0.7);
	opacity: 0;
}
