// .frontpage-module {
// 	margin-bottom: $spacing;
// 	position: relative;
// 	&--event {
// 		background: radial-gradient($c-event, transparent);
// 		transition: $trans;
//
// 		&:hover {
// 			background: radial-gradient($c-event 70%, transparent);
// 		}
//
// 	}
// 	&--project {
// 		background: radial-gradient($c-project, transparent);
// 		transition: $trans;
//
// 		&:hover {
// 			background: radial-gradient($c-project 70%, transparent);
// 		}
// 	}
// 	&--news {
// 		background: radial-gradient($c-news, transparent);
// 		transition: $trans;
//
// 		&:hover {
// 			background: radial-gradient($c-news 70%, transparent);
// 		}
// 	}
// 	&__svg {
// 		position: absolute;
// 		top: 40%;
// 		left: 50%;
// 		transform: translate(-50%, -50%);
// 		width: 60%;
// 		.circle-text {
// 			fill: $c-event;
// 			font-size: 30px;
// 			font-family: $font-family;
// 			width: 100%;
// 			// transform: skew(30deg);
// 		}
// 	}
// 	&__content {
// 		padding: $spacing;
// 	}
// 	&__title {
// 		margin: 0;
// 		margin-bottom: $spacing--small;
// 	}
// }
//
// .title-text {
// 	&.second,
// 	&.third {
// 		fill: $c-event;
// 	}
// 	&.first {
// 		fill: white;
// 	}
// }

.frontpage-module {
	$size: 187px;
	display: flex;
	max-height: $size;
	margin-top: $spacing;
	overflow: hidden;
	color: black;
	box-shadow: 0 0 50px rgba(black, .2);
	background: white;

	&--event {
		.frontpage-module__category {
			border-bottom: $border-width--large solid $c-event;
		}
	}
	&--project {
		.frontpage-module__category {
			border-bottom: $border-width--large solid $c-project;
		}
	}
	&--news {
		.frontpage-module__category {
			border-bottom: $border-width--large solid $c-news;
		}
	}

	&:hover {
		.frontpage-module__media {
			img {
				// transform: skew(3deg);
				transform: scale(1.01);
			}
		}
		&.frontpage-module--event {
			.frontpage-module__title {
				border-color: $c-event;
			}
		}
		&.frontpage-module--project {
			.frontpage-module__title {
				border-color: $c-project;
			}
		}
		&.frontpage-module--news {
			.frontpage-module__title {
				border-color: $c-news;
			}
		}
	}

	&--large {
		min-height: 349px;

		.frontpage-module__skew {
			height: 800px;
			width: 400px;
			left: -300px;

			&--invert {
				left: -325px;
			}

		}
	}

	&__category {
		display: inline-block;
		width: auto;
		padding: $spacing--xsmall 0 2px;
		color: black;
		font-family: $font-family;
		margin-bottom: $spacing--small;
	}

	&__title {
		display: inline;
		padding: 0;
		line-height: 32px;
		border-bottom: $border-width--large solid transparent;
		transition: $trans--fast;
	}

	&__content {
		width: 40%;
		padding: $spacing;
		position: relative;
		z-index: 2;
		// overflow-y: hidden;
	}
	&__media  {
		width: 60%;
		position: relative;
		overflow: hidden;
		img {
			width: 105%;
			transition: .3s ease;
			min-height: $size;
		}
	}
	&__skew {
		position: absolute;
		background: white;
		width: $size;
		height: $size*2;
		transform: rotate(-15deg);
		left: -$size/2;
		top: 0;

		&--invert {
			transform: rotate(195deg);
			top: -$size;
		}
	}
	&__excerpt {
		padding-top: $spacing;
		height: $spacing*4;
		overflow: hidden;
	}
}
