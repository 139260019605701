%clearfix, .cf {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.fade-out-in {
  animation: fadeOutIn .8s linear;
}

.list {
  margin-bottom: $spacing;
  &--reset {
    margin-bottom: $spacing;
    list-style: none;
    padding: 0;
    li:before {
      display: none;
    }
    a {
      border-bottom: none;
    }
  }
  &--lines {
    li {
      border-bottom: $border-width solid $c-white--overlay;
      padding: $spacing--small 0;
    }
  }
}

.bg-grey {
  background: $c-grey;
  width: 100%;
}

.no-link {
  border-bottom: 0px solid;
}

.semi {
  font-family: $font-family;
}

.center-text {
  text-align: center;
}

.to-top {
  display: block;
  border: 1px solid $c-violet;
  padding: $spacing;
  transition: $trans--fast;
  .icon__arrow {
    fill: none;
    stroke: $c-violet;
    stroke-width: 3px;
    transition: $trans--fast;
  }

  &:hover {
    background: $c-violet;

    .icon__arrow {
      stroke: white;
      transform: translateY(-10px);
    }
  }
}
