.nav {
	font-family: $font-family;
	background: white;
	padding: $spacing--small 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
    box-shadow: 0 0 10px rgba(0,0,0,.5);

	&__list {
		list-style: none;
	}
	&__item {
		display: inline-block;
		margin-right: $spacing;
		&--is-active {
			border-bottom: $border-width solid;
		}
	}
	a {
		border-bottom: none;
		color: black;
	}
	&__search {
		width: 25px;
		position: absolute;
		cursor: pointer;

		&--is-active {
			transform: none;
		}
	}
}
