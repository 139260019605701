.logos {
	display: none;
	@include bp($sm) {
		display: flex;
		justify-content: flex-start;
		padding: $spacing 0;
		align-items: center;
		flex-wrap: wrap;
	}
	@include bp($lg) {
		flex-wrap: nowrap;
	}

	&__item {
		padding: 0 $spacing--large/2;

		img {
			transition: $trans--fast;
			filter:grayscale(100%);
		}

		&:hover {
			img {
				filter:grayscale(0%);
			}
		}
	}
}
