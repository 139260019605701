.logo {
    position: fixed;
    z-index: 10;
    top: $spacing--small;
    right: 0;
    margin-right: $sidespace--lg;
    width: 70px;
}

// .logo {
//     position: absolute;
// 	z-index: 9999;
//     color: green;
//     top: 0px;
//     right: 20px;
//     perspective: 1000px;
//     font-family: 'Replica-bold';
//     letter-spacing: -2px;
//     color: black;
//     font-size: 40px;
//     line-height: 40px;
//     transform-origin: top left;
//     text-transform: uppercase;
//     //animation: logo__main 1s ease forwards;
//     //transform: rotateY(38deg) skewY(14.2deg);
//
//     // ADDED WITH NEW SVG LOGO
//     width: 70px;
//     // top: $spacing--small;
//     &__ture {
// //        color: $c-grey;
//         display: inline-block;
//         transform-origin: top left;
//        //animation: logo__secondary 1s 1s ease forwards;
//         //transform: rotateY(0) skewY(-42.4deg) translateY(-1px) translateX(-2px);
//     }
//     &__first {
//         margin-bottom: 0;
//     }
//     &__second {
//         display: block;
//         margin-top: 0;
//     }
//     &--white {
//         .logo__first,
//         .logo__second {
//             color: white;
//         }
//     }
// }
