.article {
	position: relative;
	padding-bottom: $spacing--section;
	&__title {
	}
	&__sub-title {
		margin-top: -$spacing;
	}
	&__hero {
		position: relative;
		margin-bottom: $spacing--large;
		box-shadow: -7px 0 50px rgba(black, .4);
		margin-top: 93px;
		max-height: 60vh;
		overflow: hidden;

		&--top {
			img {
				top: 0;
			}
		}
		&--middle {
			img {
				top: 50%;
			}
		}
		&--bottom {
			img {
				bottom: 0;
			}
		}
		img {
			position: relative;
			width: 100%;
			padding-bottom: 0;
		}
		margin-left: $spacing--small;
		@include bp($sm) {
			margin-left: columns($sm,1);
		}
		@include bp($md) {
			margin-left: columns($md,1);
		}
		@include bp($lg) {
			margin-left: columns($lg,1);
		}
	}
	&__close {
		position: fixed;
		top: 0;
		margin-left: -$spacing--large*2;
		width: $spacing--large;
		height: $spacing--large;
		background: $c-gold;
	}
	&__date {
		margin-bottom: $spacing;
	}
	&__img {
		max-height: 500px;
		width: auto;
	}
	.main-gallery {
		height: 500px;

		@include bp($sm) {
			margin-right: - columns($sm, 1);
		}
		@include bp($md) {
			margin-right: - columns($md, 1);
		}
		@include bp($lg) {
			margin-right: - columns($lg, 1);
		}

		img {
			height: 500px;
			padding-bottom: 0;
		}
	}
}
