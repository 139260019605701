body, input, textarea {
	-webkit-font-smoothing: antialiased;
	font-family: $font-family--light;
	font-size: $p__fz;
	line-height: $p__lh;
}

button,
select {
	font-family: $font-family;
	font-size: $meta__fz;
	line-height: $meta__lh;
}

h1,h2,h3,h4,h5,p,li,a,dd,dt,input,label {
	font-weight: normal;
}

h1,h2,h3 {
	// margin-top: $spacing;
}

// Setting margin-bottom on headings
// @for $i from 1 through 3 {
// 	h#{$i} {
// 		font-family: $font-family;
// 		letter-spacing: .03em;
// 		margin-bottom: $baseline * (4 - $i);
// 		margin-top: $baseline * (5 - $i);
// 	}
// }

h2,h3 {
	font-family: $font-family--bold;
	// letter-spacing: .03em;
	// padding-top: $spacing;
	// padding-bottom: $spacing--small;
}

h1 {
	font-family: $font-family--bold;
	font-size: $h1__fz;
	line-height: $h1__lh;
}

h2 {
	font-size: $h2__fz;
	line-height: $h2__lh;
	// margin-top: $baseline*3;
	// margin-bottom: $baseline*2;
}

h3 {
	font-family: $font-family;
	font-size: $h3__fz;
	line-height: $h3__lh;
	// margin-top: $baseline*2;
	// margin-bottom: $spacing--xsmall;
}

p {
	margin-bottom: $spacing--small;
}

.lead {
	font-family: $font-family--italic;
}
.meta {
	font-size: $meta__fz;
	line-height: $meta__lh;
	letter-spacing: .03em;
}
strong {
	font-family: $font-family--bold;
}

article {
	h1 {
		padding-bottom: $spacing;

		span {
			font-family: $font-family--light;
		}
	}
	img {
		padding-bottom: $spacing;
	}

	p a {
		font-family: $font-family;
	}

	h2,
	h3 {
		padding-bottom: $spacing--small;
	}
	p + h3 {
		padding-top: $spacing;
	}
	p + h2,
	ul + h2 {
		padding-top: $spacing--large;
	}

	section {
		padding-bottom: $spacing;
		p:last-of-type {
			padding-bottom: 0;
		}
	}
	p + section:not('.lead') {
		padding-top: $spacing--large;
	}
	ul {
		padding-bottom: $spacing;
	}
	.lead {
		padding-bottom: $spacing--large;
		margin-bottom: 0;

		+ .author {
			margin-top: -$spacing;
		}
	}

	ng-map {
		height: 300px;
	}

	p + .factbox {
		margin-top: 0;
		margin-bottom: $spacing--large;
	}
	table {
		width: 100%;
		margin-bottom: $spacing--large;
	}
	table.overlay-slide__indent {
		@include bp($sm) {
			width: calc(100% - #{columns($sm, 2)})
		}
		@include bp($md) {
			width: calc(100% - #{columns($md, 2)})
		}
		@include bp($lg) {
			width: calc(100% - #{columns($lg, 2)})
		}
	}

	.front-module {
		height: 300px;
		&__content {
			width: 100%;
			@include bp($sm) {
				width: 80%;
			}
		}
		h2 {
			padding-top: 0;
			padding-bottom: 0;
		}
		img {
			padding-bottom: 0;
		}
	}
}
