.table {
	margin-bottom: $spacing--large;
	&__num {
		text-align: right;
		padding-right: 0;
	}

	&__title {
		@extend .meta;
		font-family: $font-family;
	}
}

.section {
	// margin-bottom: $spacing--section;
	margin-bottom: $spacing;
}

@keyframes fadeOutIn {
	0% {
		opacity: 1;
		margin-top: 0px;
	}
	20% {
		opacity: 0;
		margin-top: 0px;
	}
	80% {
		opacity: 0;
		margin-top: 100px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
}

.logo--fixed {
	position: absolute;
	top: 50%;
	left: 50%;
	perspective: 1000px;
	margin-left: -7px;
	margin-top: -18px;
	font-family: 'Replica-bold';
	letter-spacing: -2px;
	color: $c-blue;
	font-size: 118px;
	line-height: 118px;
	transform-origin: top left;
	text-transform: uppercase;
	animation: logo__main 1s ease forwards;
	&__ture {
		//        color: $c-grey;
		display: inline-block;
		transform-origin: top left;
		animation: logo__secondary 1s 1s ease forwards;
	}
	&__first {
		margin-bottom: 0;
	}
	&__second {
		display: block;
		margin-top: -13px;
	}
}

@keyframes logo__main {
	0% {
		transform: rotateY(0deg) skewY(0deg) translate(-50%,-50%);
		transform-origin: top left;
	}
	100% {
		transform: rotateY(38deg) skewY(24.2deg) translate(-50%,-50%);
		transform-origin: top left;
	}
}
@keyframes logo__secondary {
	0% {
		transform: skewY(0deg);
		color: $c-blue;
	}
	100% {
		color: $c-grey;
		transform: rotateY(0) skewY(-42.4deg) translateY(-1px) translateX(-2px);
	}
}

.figure {
	margin-bottom: $spacing;

	&__img {
		padding-bottom: 0;
	}

	&__caption {
		@extend .meta;
		background: rgba(black, .05);
		padding: $spacing--xsmall;
	}
}
#facebook-logo {
	display: inline-block;
	height: $spacing;
	width: $spacing;
	float: left;
	margin-right: $spacing--small;
	fill: $c-violet;
	transition: $trans--fast;
	& + p {
		display: inline-block;
		float: left;
	}
}
.facebook {
	display: block;
	margin-top: $spacing;

	&:hover {
		#facebook-logo {
			fill: black;
		}
	}
}
