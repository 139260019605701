.projects-list {
	&__item {
		border-bottom: $border-width--small solid $border-color--light;
		padding: $spacing 0;

		&:last-of-type {
			border-bottom: none;
		}

		&--is-active {
			background: $c-project;
			margin: 0 (-$spacing);
			padding: $spacing;
			.project-list--event .project-list__item {
				background: $c-event;
			}
			.project-list--news .project-list__item {
				background: $c-news;
			}
			.project-list__item {

			}
		}

		&:hover {
			.projects-list__title--event h2{
				border-color: $c-event;
			}
			.projects-list__title--project h2{
				border-color: $c-project;
			}
			.projects-list__title--news h2{
				border-color: $c-news;
			}
			.projects-list__title--blog h2{
				border-color: $c-blog;
			}
		}

		@include bp($sm) {
			padding-left: $spacing;
			margin-left: -$spacing;
		}
	}
	&__title {
		margin: 0;
		margin-bottom: $spacing;
		padding-top: 0;

		h2 {
			display: inline;
			border-bottom: 3px solid transparent;
			transition: $trans--fast;
		}
	}
	&__time {
		@extend .meta;
		// color: $c-violet;
		display: block;
		margin-bottom: $spacing--xsmall;
	}
	&__body {
		@extend .cf;
	}
	&__lead {
		margin: 0;
		width: 100%;

		@include bp($sm) {
			// @include columns($sm, 5);
		}
		@include bp($md) {
			float: left;
			@include columns($md, 5);
		}
		@include bp($lg) {
			@include columns($lg, 5);
		}
	}
	&__media {
		display: inline-block;
		width: 100%;

		@include bp($sm) {
			// @include columns($sm, 5);
		}
		@include bp($md) {
			max-height: $spacing*10;
			padding-left: $spacing;
			@include columns($md, 5);

			img {
				max-height: 200px;
				width: auto;
			}
		}
		@include bp($lg) {
			@include columns($lg, 5);
		}
	}
}
