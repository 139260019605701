.filter {
	max-height: 0;
	overflow: hidden;
	transition: $trans--fast;

	&--is-open {
		margin: $spacing 0;
		max-height: 600px;
		height: auto;
	}

	&__group {
		padding: $spacing--small 0;
		border-bottom: $border-width solid $c-grey--light;

		&:first-of-type {
			border-top: $border-width solid $c-grey--light;
		}
	}
	&__label {
		font-family: $font-family;
		@extend .meta;

	}
	&__item {
	}
	&__button {
		margin: $spacing $spacing $spacing 0;
	}

	&__toggle {
		transition: $trans--fast;
		&:focus {
			outline: none;
		}
	}

	&__more {
		max-height: 0;
		overflow: hidden;
		transition: $trans--fast;

		&--is-open {
			max-height: 400px;
			height: auto;
		}
	}

}
