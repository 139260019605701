.list {
	margin-bottom: $spacing;
	&__item {
		padding-bottom: $spacing--xsmall;
		&:before {
			content: "";
			display: inline-block;
			position: relative;
			left: -$spacing--xsmall / 1.5;
			transform: translateY(-2px);
			margin-left: -$spacing--xsmall*1.5; // reset width of point
			width: $spacing--xsmall;
			height: $spacing--xsmall;
			background: black;
			border-radius: 50%;
		}
	}
	&--reset {
		@extend .no-list;
	}
}
