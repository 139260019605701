.program {
	background: $c-white--overlay;
	padding-bottom: $spacing--large;
	width: 100%;


	// Global
	&__title,
	&__time {
		margin-top: 0;
		padding-top: 0;
		padding-bottom: $spacing--xsmall;
	}
	&__place {
		@extend .meta;
		display: inline-block;
	}

	// Header
	&__header {
		margin-bottom: $spacing;
	}
	&__duration {
		background: white;
		padding: $spacing--small;

		time {
			display: block;
			margin-bottom: $spacing--xsmall;
		}
	}
	&__map {
		max-height: 160px;
		width: 100%;

		ng-map {
			height: 160px;
		}
	}

	// Item
	&__item {
		margin: 0;
		padding: 0;
		margin-bottom: $spacing;

		&--group {
			margin-bottom: 0;
			border-bottom: $border-width solid $c-blue;
		}
	}

	&__when {
		background: $c-grey--light;
		padding: $spacing--small $spacing;
	}

	&__what {
		background: white;
		padding: $spacing--small;
	}

	&__time {
		display: inline-block;
		margin-right: $spacing;
	}

	// Section title
	&__section-title {
		// padding: $spacing;
		h3 {
			padding: 0;
			margin: 0;
		}
	}

	@include bp($md) {
		&__header {
			display: flex;
			padding: 0;
			margin-bottom: $spacing;

			&--on-img {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 600px;
				margin: 0;

				.program__duration {
					background: rgba(white,.9);
				}

				.program__map {
					display: none;
				}
			}
		}
		&__duration {
			background: white;
			width: 40%;
			padding: $spacing;
		}
		&__map {
			width: 60%;
		}

		// Item
		&__item {
			margin: 0 $spacing $spacing--small;
			display: flex;
			box-sizing: border-box;
			padding: 0;

			&--group {
				margin-bottom: 0;
				border-bottom: $border-width solid $c-blue;
			}
		}

		&__place {
			display: block;
		}

		&__when {
			width: 20%;
			background: $c-grey--light;
		}

		&__what {
			width: 80%;
			background: white;
		}

		// Section title
		&__section-title {
			margin-left: $spacing;
			padding: $spacing 0;
		}

	}
}
