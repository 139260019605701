.search-module {
	position: fixed;
	height: 200px;
	margin-top: -300px;
	z-index: 9;
	background: $c-grey--light;
	transition: .3s ease;
	width: 100%;

	@include bp($md) {
		padding: $spacing--large;
	}

	&--is-open {
		margin-top: 0;
	}
}
