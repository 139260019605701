.form {
	$size: $spacing--small;

	&__group {
		margin-bottom: $spacing--small;
	}

	&__search {
		display: block;
		width: 95%;
		border: none;
		background: none;
		margin: 0 auto;
		border-bottom: $border-width solid white;
		color: black;
		margin-top: 100px;
		max-width: $lg;

		&::placeholder {
			color: black;
		}

		@include bp($md) {
			margin-top: 0;
			width: 60%;
			font-size: 2.4vw;
			line-height: $spacing--small;
			border-bottom: $border-width--large solid white;
			padding: $spacing $spacing $spacing--xsmall $spacing;

			&::placeholder {
				padding-top: $spacing--small;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}

	&__checkbox {
		display: block;
		margin-bottom: $spacing--xsmall;

		@extend .cf;

		&__helper {
			display: inline-block;
			position: absolute;
			width: $size;
			height: $size;
			border: 1px solid $c-violet;
			margin-top: 2px;
			box-shadow: inset 0 0 0 100px white,
			inset 0 0 0 100px $c-violet;
			transition: $trans--fast;
		}
		&__label {
			display: inline-block;
			margin-left: $spacing--small + $spacing--xsmall;
			font-family: $font-family--light;
		}
		input {
			display: none;
		}
		input[type="checkbox"]:checked {
			+ .form__checkbox__helper {
				box-shadow: inset 0 0 0 3px white,
				inset 0 0 0 $baseline $c-violet;

				&:after {
					// content: "✔";
					display: inline-block;
					width: 100%;
					color: white;
					line-height: $size - 2;
					text-align: center;
				}
			}
		}
		&__select {
			width: 100%;
			line-height:$size;
			border: $border-width solid $border-color;
			padding-left: $baseline/2;
			margin-bottom: $baseline;
		}
	}
	&__radio {
		display: inline-block;
		margin: $baseline 0;

		&__helper {
			display: inline-block;
			width: $size;
			height: $size;
			border-radius: 50%;
			border: 1px solid $c-violet;
			margin-right: $spacing--xsmall;
			box-shadow: inset 0 0 0 100px white,
			inset 0 0 0 100px $c-violet;
			@extend .trans-fast;
		}
		&__label {

		}
		input {
			display: none;
		}
		input[type="radio"]:checked {
			+ .form__radio__helper {
				background: white;
				box-shadow: inset 0 0 0 3px white,
				inset 0 0 0 $baseline $c-violet;
			}
		}
		&__select {
			width: 100%;
			line-height:$size;
			border: $border-width solid $border-color;
			padding-left: $baseline/2;
			margin-bottom: $baseline;
		}
		&__desc {
			margin-left: 39px;
		}
	}
}
