.article-menu {
	width: 100%;
	position: fixed;
	z-index: 9;
	padding: $spacing--small 0 $spacing--small $spacing--large;


	&--event {
		background: white;
	}
	&--project {
		background: white;
	}
	&--blog {
		background: none;
	}
	&--news{
		background: none;
	}

	&--article {
		padding-left: $spacing--small;
		box-shadow: 0 5px 10px rgba(black,.2);
		background: white;
		top: 53px;

		@include bp($sm) {
			padding-left: $sidespace--sm;
		}
		@include bp($md) {
			padding-left: $sidespace--md;
		}
		@include bp($lg) {
			padding-left: $sidespace--lg;
		}
		.article-menu__item:first-of-type {
			padding-left: $spacing--small;
		}
	}

	&__item {
		font-family: $font-family--light;
		display: inline-block;
		margin-right: $spacing;
		padding-bottom: $spacing--xsmall;

		.is-active {
			border-bottom: 1px solid;
		}
	}
	a {
		border-bottom: none;
		color: black;
	}

	&--second {
		.article-menu__item {
			display: none;
		}
	}
	&__close {
		color: $c-violet;
		position: absolute;
		margin-left: -$spacing--large;
		font-size: $spacing;
		top: 0;
		left: $spacing--large;
		border: none;
		width: $spacing--large;
		height: $spacing--large;
		background: none;
		transition: $trans--fast;

		.icon__close {
			stroke: $c-violet;
			stroke-width: 5px;
			width: 40%;
		}

		&:hover {
			transform: rotate(90deg);
			transform-origin: center;
		}
	}
	.button--small {
		margin-top: -2px;
	}
}
