// Mixin for breakpoints
@mixin bp($size) {
    @media screen and (min-width: $size) {
       @content;
    }
}

// Mixin for making columns — used in overlay-slide
@mixin columns($bp, $amount) {
    $col: ($bp / 12) - $gutter;
    $width: ($col * $amount) + $gutter * ($amount - 1);
    width: $width + $gutter/2;
}

@function columns($bp, $amount) {
    $col: ($bp / 12) - $gutter;
    $width: ($col * $amount) + $gutter * ($amount - 1);
    @return $width + $gutter/2;
}
