/*
Theese are the variable definitions

$gutter: 30px;
$sm: 768px - $gutter; // - $gutter to compensate for container padding
$md: 992px - $gutter;
$lg: 1200px - $gutter;

// Sidespace

$sidespace: 0;

$sidespace--sm: calc((100vw - #{$sm}) / 2);
$sidespace--md: calc((100vw - #{$md}) / 2);
$sidespace--lg: calc((100vw - #{$lg}) / 2);

$sidespace-neg--sm: calc((-100vw + #{$sm}) / 2);
$sidespace-neg--md: calc((-100vw + #{$md}) / 2);
$sidespace-neg--lg: calc((-100vw + #{$lg}) / 2);
*/
.overlay-slide {
	box-sizing: content-box;
	position: fixed;
	background: $c-gold;
	top: 53px; // Height of .nav
	right: -100vw;
	height: calc(100vh - 53px);
	min-height: calc(100vh - 53px);
	width: 100vw;
	z-index: 9;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-x: visible;
	transition: $trans;
	box-shadow: -5px 0 2px -2px rgba(black,.15);

	&--project {
		background: $c-project;
	}
	&--news {
		background: $c-news;
	}
	&--event {
		background: $c-event;
	}
	&--blog {
		background: $c-blog;
	}

	&--is-open {
		right: 0;
		// animation: skew .3s $bezier;

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 50px;
			position: fixed;
			z-index: 0;
			top: 52;
			left: 0;
		}
	}

	&--fade {
		.article {

		}
	}

	@include bp($sm) {
		padding-right: $sidespace--sm;
		@include columns($sm, 11);
	}
	@include bp($md) {
		padding-right: $sidespace--md;
		@include columns($md, 11);
	}
	@include bp($lg) {
		padding-right: $sidespace--lg;
		@include columns($lg, 11);
	}

	&__content {
		padding: 0 $spacing--small $spacing--small;

		@include bp($sm) {
			max-width: columns($sm, 11);
			padding: 0 0 $spacing--large columns($sm, 1);
		}
		@include bp($md) {
			max-width: columns($md, 9);
			padding: 0 0 $spacing--large columns($md, 1);
		}
		@include bp($lg) {
			max-width: columns($lg, 9);
			padding: 0 0 $spacing--large columns($lg, 1);
		}

		.section {
			padding-bottom: $spacing;
		}
	}

	&--is-half-open {

		@include bp($sm) {
			// right: - columns($lg, 4);
			// padding-right: $sidespace--sm;
		}
		@include bp($md) {
			right: - columns($lg, 4);
			padding-right: $sidespace--md;
		}
		@include bp($lg) {
			// @include columns($lg, 7);
			right: - columns($lg, 4);
			padding-right: $sidespace--lg;
		}
	}

	.main-gallery--main {
		margin-top: 78px;
		@include bp($md) {
			margin-left: $spacing--large;
		}
		&--main {
			margin-top: 93px; // height of overlay menu
		}

		img {
			padding-bottom: 0;
		}
	}

	&__indent {
		max-width:100%;

		@include bp($sm) {
			max-width:columns($sm, 8);
			margin-left: columns($sm, 2);
		}
		@include bp($md) {
			max-width:columns($md, 8);
			margin-left: columns($md, 2);
		}
		@include bp($lg) {
			max-width:columns($lg, 8);
			margin-left: columns($lg, 2);
		}
	}
	&__half-indent {
		max-width: 100%;
		@include bp($sm) {
			margin-left: columns($sm, 1);
			max-width:columns($sm, 10);
		}
		@include bp($md) {
			margin-left: columns($md, 1);
			max-width:columns($md, 10);
		}
		@include bp($lg) {
			margin-left: columns($lg, 1);
			max-width:columns($lg, 10);
		}
	}
}
