.speakers {
	&__item {
		margin-bottom: $spacing--large;
	}
	&__wrapper {
		@extend .cf;
		margin-bottom: $spacing--small;
	}
	&__name {
		float: left;
		width: calc(70% - #{$spacing});
	}
	&__media {
		float: left;
		width: 30%;
		margin-right: $spacing;

		img {
			padding-bottom: 0;
		}
	}
}
