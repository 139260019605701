.front-module {
    display: block;
    margin: 0;
    width: 100%;
    max-height: 40vh;
    overflow: hidden;
    position: relative;
    min-height: 320px;
    margin-bottom: $spacing;

    @include bp($sm) {
        margin-bottom: 0;
    }

    &:hover {
        img {
            transform: scale(1.01);
        }
    }
    &:hover {
        &.front-module--event {
            .front-module__title {
                border-bottom: $border-width--large solid $c-event;
            }
        }
        &.front-module--project {
            .front-module__title {
                border-bottom: $border-width--large solid $c-project;
            }
        }
        &.front-module--news {
            .front-module__title {
                border-bottom: $border-width--large solid $c-news;
            }
        }
        &.front-module--blog {
            .front-module__title {
                border-bottom: $border-width--large solid $c-blog;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        background: rgba(white,.9);
        padding: $spacing;

        @include bp($sm) {
            position: absolute;
            top: $spacing;
            left: $spacing;
            width: 320px;
            z-index: 1;
        }
    }

    &__category {
        @extend .meta;
    }

    &__title {
        margin-bottom: $spacing;
        display: inline-block;
        border-bottom: $border-width--large solid transparent;
    }

    &__img {
        // min-width: 100%;
        min-height: 100%;
        transition: $trans;
    }

    &--small {
        @include bp($sm) {
            width: 50%;
            float: left;
            height: 30vh;

            .front-module__content {
                margin: 0 auto;
            }
        }
    }
}
